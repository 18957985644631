import cls from './Description.module.scss';
import ourExperience from '../../../assets/image/experience.png';
import okIcon from '../../../assets/image/okIcon.svg';
import { WidthLimiter } from '../../UI/WidthLimiter/WidthLimiter';

const Description = () => {
  return (
    <div className={cls.Description} id="about-us">
      <WidthLimiter>
        <div className={cls.container}>
          <div className={cls.image}>
            <img src={ourExperience} alt="imageSystem" />
          </div>
          <div className={cls.content}>
            <div className={cls.topText}>— О Skypay</div>
            <h2>
              Простой и надежный финансовый сервис в Кыргызской Республике
            </h2>
            <p>
              Объединив усилия и опыт лучших профессионалов отрасли, платежный сервис Skypay уверенно завоевал
              неоспоримую
              популярность и доверие пользователей.
            </p>
            <p>Пользователи Skypay могут:</p>
            <div className={cls.capabilities}>
              <div>
                <img src={okIcon} alt="ok" />
                <p>осуществлять платежи и денежные переводы;</p>
              </div>
              <div>
                <img src={okIcon} alt="ok" />
                <p>оплачивать штрафы;</p>
              </div>
              <div>
                <img src={okIcon} alt="ok" />
                <p>пополнять мобильный телефон;</p>
              </div>
              <div>
                <img src={okIcon} alt="ok" />
                <p>платить за услуги Интернет-провайдеров.</p>
              </div>
            </div>
          </div>
        </div>
      </WidthLimiter>
    </div>
  );
};

export default Description;
