import { useCookies } from "react-cookie";
import {Button} from "@supermegapay/common";
import policy from "../../assets/pdf/policy.pdf";
import cls from "./CookieConsent.module.scss";

const CookieConsent = () => {
  /* eslint-disable */
  const [cookies, setCookie] = useCookies(["cookieConsent"]);

  const giveCookieConsent = () => {
    setCookie("cookieConsent", true, { path: "/" });
  };

  return (
    <div className={cls.banner}>
      <div>Чтобы улучшить наш сервис, мы используем cookie и аналогичные технологии.
        <a target="_blank" rel="noreferrer" href={policy}> Узнайте подробнее</a>
      </div>
      <div className={cls.button}><Button onClick={giveCookieConsent}>Хорошо</Button></div>
    </div>
  );
};

export default CookieConsent;
