import {InputContainer} from "../UI/InputContainer/InputContainer";
import {useEffect} from "react";

export const SelectCompt = (props) => {
  const {changeOption, paymentOption, options, style} = props;

  const changeSelect = (e) => {
    const newOption = options.find((option) => option.value === e.target.value)
    const {value, min, max, comissions, currency = 'RUB'} = newOption;

    changeOption(value, comissions.value, min, max, currency)
  };

  useEffect(()=>{
    const {value, comissions, min, max, currency = 'RUB'} = options[0];
    changeOption(value, comissions.value, min, max, currency)
  },[])

  return (
    <InputContainer>
      <label htmlFor="cardSelect">
        Выберите источник платежа
      </label>
      <select
        id="cardSelect"
        value={paymentOption}
        onChange={changeSelect}
        className={style.FormInput}
      >
        {
          options.map((item) => (
            <option key={item.value} value={item.value}>
              {item.title}
            </option>
          ))
        }
      </select>
    </InputContainer>
  );
};
