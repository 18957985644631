import {useContext, useEffect, useState} from "react";
import {FormTitle, Button} from "../index";
import cls from "./Form1.module.scss";
import {PAY_OPTION_ID_SBP} from "../../utils";
import {sendSbpForm} from "../../utils/axios/sendSbpForm";
import {useNavigate} from "react-router";

export const Form2 = (props) => {
  const {onChangeStage, formContextProp} = props;
  const navigate = useNavigate();
  const {formData, setFormData} = useContext(formContextProp);
  const [buttonDisable, setButtonDisable] = useState(false);

  const onSubmitForm = async (e) => {
    e.preventDefault();
    setButtonDisable(true)

    if (formData.payment_option === PAY_OPTION_ID_SBP) {
      const urlToQr = await sendSbpForm(formData)
      setFormData({...formData, url: urlToQr})
      navigate(`/result?user_ref=${formData.user_ref}`)
    } else {
      onChangeStage();
    }

    setButtonDisable(false)
  };

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: "smooth"});
  }, []);

  return (
    <form onSubmit={onSubmitForm}>
      <FormTitle>Подтверждение</FormTitle>
      <div className={cls.inputsWrap}>
        <div className={cls.dataForm}>
          <h4>{formData.fields["account.value.persacc"] ? 'Номер' : 'Лицевой счёт' }</h4>
          <p>{formData.fields["account.value.persacc"] || formData.fields["account.value"]}</p>
        </div>
        <div className={cls.dataForm}>
          <h4>Сумма платежа</h4>
          <p>{formData.fields.total} {formData.currency}</p>
        </div>
        <div className={cls.dataForm}>
          <h4>Комиссия</h4>
          <p>{formData.commission} {formData.currency}</p>
        </div>
        <div className={cls.dataForm}>
          <h4>Электронный адрес для чека</h4>
          <p>{formData.mail}</p>
        </div>
        <div className={cls.dataForm}>
          <h4>Магазин</h4>
          <p>{formData.shop}</p>
        </div>
        <div className={cls.dataForm}>
          <h4>Заказ</h4>
          <p>{formData.user_ref}</p>
        </div>
        <Button type="submit" isLoading={buttonDisable} disabled={buttonDisable}>К оплате</Button>
      </div>
    </form>
  );
};
