import { useState } from "react";
import cls from "./NavBar.module.scss";
import logo from "../../assets/image/skypayLogo.svg";
import { Link } from "react-router-dom";

const NavBar = () => {
  const [showMenu, setShowMenu] = useState(false);

  const onClickBurger = () => {
    setShowMenu((prevState) => !prevState);
  };

  const onCloseMenu = () => {
    setShowMenu(false);
  };

  const menuShowStyle = `${cls.navLinks} ${showMenu ? cls.navLinksShow : ""}`;
  const burgerStyle = `${cls.navBurger} ${showMenu ? cls.burgerClose : ""}`;

  return (
    <header className={cls.NavBar}>
      <div className={cls.wrapper}>
        <div className={cls.container}>
          <Link to={"/"}>
            <img src={logo} alt="logo" />
          </Link>
          <nav className={menuShowStyle}>
            <ul className={cls.list}>
              <li>
                <a href="#catalog" className={cls.link} onClick={onCloseMenu}>
                  Каталог услуг
                </a>
              </li>
              <li>
                <a href="#about-us" className={cls.link} onClick={onCloseMenu}>
                  О Skypay
                </a>
              </li>
              <li>
                <a href="#questions" className={cls.link} onClick={onCloseMenu}>
                  Актуальные вопросы
                </a>
              </li>
            </ul>
          </nav>
          <button className={burgerStyle} onClick={onClickBurger}></button>
        </div>
      </div>
    </header>
  );
};

export default NavBar;
