import React from "react";
import {Link} from "react-router-dom";
import cls from "./CatalogBlock.module.scss";

const CatalogList = (props) => {
  const {catalog} = props;

  return (
    <div className={cls.catalog}>
      {catalog.map((item) => (
          <Link
            key={item.id}
            className={cls.item}
            to={`/pay/${item.id}`}
          >
            <div className={cls.image}>
              <img src={item.data.logo.url} alt="icon"/>
            </div>
            <p>{item.data.name_ru}</p>
          </Link>
        )
      )}
    </div>
  );
};

export default CatalogList;
