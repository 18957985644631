import cls from './AboutUs.module.scss';
import arrowLeft from '../../../assets/image/arrowLeft.svg';
import arrowCollapse from '../../../assets/image/collapseArrow.svg';
import { useState } from 'react';
import { WidthLimiter } from '../../UI/WidthLimiter/WidthLimiter';

const CollapseItem = ({ children, title }) => {
  const [text, setText] = useState(false);

  return (
    <div className={cls.item}>
      <button
        onClick={() => setText(prev => !prev)}
        className={`${cls.button} ${text ? cls.active : ''}`}
      >
        <div>{title}</div>
        <img src={arrowCollapse} alt="collapse" />
      </button>
      {text && <div className={cls.text}>{children}</div>}
    </div>
  );
};

const AboutUs = () => {
  return (
    <div className={cls.AboutUs} id="questions">
      <WidthLimiter>

        <div className={cls.container}>
          <div className={cls.content}>
            <CollapseItem title={'Как перевести средства на банковскую карту РФ?'}>
              <p>Перевести денежные средства на банковскую карту России можно следующим образом:</p>
              <p>1. Выберите из услуг на сайте Skypay.kg «Банковские карты РФ» (пополнение карты).</p>
              <p>2. Введите номер карты Получателя, сумму операции в рублях и электронный адрес для чека.</p>
              <p>3. Проверьте сумму списания, с учетом комиссии. Выберите тип карты Отправителя. Нажмите «Перейти к
                оплате».</p>
              <p>4. В открывшейся платежной форме введите реквизиты банковской карты.</p>
              <p>5. Получите и введите код 3DS для подтверждения операции.</p>
              <p>6. Операция успешно завершена.</p>
            </CollapseItem>
            <div className={cls.line}></div>
            <CollapseItem title={'Как сделать перевод на кыргызскую банковскую карту с карты РФ?'}>
              <p>Перевести денежные средства на банковскую карту Киргизии можно следующим образом:</p>
              <p>1. Выберите из услуг на сайте Skypay.kg «Оптима Visa карта» (пополнение карты банка КР).</p>
              <p>2. Введите номер карты Получателя, сумму операции в рублях и электронный адрес для чека.</p>
              <p>3. Получите сумму списания, с учетом комиссии. Выберите тип карты Отправителя. Нажмите «Перейти к
                оплате».</p>
              <p>4. В открывшейся платежной форме введите реквизиты банковской карты Отправителя. Нажмите «Оплатить»</p>
              <p>5. Получите и введите код 3DS для подтверждения операции.</p>
              <p>6. Операция успешно завершена.</p>
            </CollapseItem>
            <div className={cls.line}></div>
            <CollapseItem title={'Как пополнить телефон с карты РФ?'}>
              <p>Пополнить баланс телефона с банковской карты России очень просто:</p>
              <p>1. Выберите из услуг на сайте Skypay.kg «Мегаком» (оплата сотового телефона).</p>
              <p>2. В окне оплаты выберите «Оплатить картой РФ».</p>
              <p>3. Введите номер телефона, сумму платежа в рублях и электронный адрес для чека.</p>
              <p>4. Получите сумму списания с учетом комиссии. Нажмите «Перейти к оплате».</p>
              <p>5. В открывшейся платежной форме введите реквизиты банковской карты.</p>
              <p>6. Получите и введите код 3DS для подтверждения операции.</p>
              <p>7. Операция успешно завершена.</p>
            </CollapseItem>
            <div className={cls.line}></div>
            <CollapseItem title={'Как быстро происходит зачисление оплаты за ЖКХ через Skypay?'}>
              Зачисление оплаты за ЖКХ через Skypay происходит в течение 2-3 рабочих дней.
            </CollapseItem>
          </div>
          <div className={cls.title}>
            <div>— Актуальные вопросы</div>
            <h2>Есть вопросы? <br /> Загляните сюда.</h2>
            <img src={arrowLeft} alt="arrow" />
          </div>
        </div>
      </WidthLimiter>
    </div>
  );
};

export default AboutUs;
