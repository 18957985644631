import cls from './TopBlock.module.scss';
import topImage from '../../../assets/image/topImage.png';
import { WidthLimiter } from '../../UI/WidthLimiter/WidthLimiter';

const TopBlock = () => {
  return (
    <div className={cls.TopBlock}>
      <WidthLimiter>
        <div className={cls.container}>
          <div className={cls.left}>
            <h1 className={cls.title}>
              Skypay всегда под рукой для онлайн оплаты
            </h1>
            <p className={cls.text}>
              Совершайте онлайн платежи через Skypay: коммунальные услуги, денежные переводы, мобильную связь — с экрана
              вашего смартфона, планшета или компьютера.
            </p>
            <div className={cls.info}>
              <div className={cls.infoItem}>
                <h4>100К+</h4>
                <p>Пользователей</p>
              </div>
              <div className={cls.infoItem}>
                <h4>100+</h4>
                <p>Партнеров</p>
              </div>
              <div className={cls.infoItem}>
                <h4>50К+</h4>
                <p>Ежедневных платежей</p>
              </div>
            </div>
          </div>
          <div className={cls.right}>
            <img src={topImage} alt="topImage" />
          </div>
        </div>
      </WidthLimiter>
    </div>
  );
};

export default TopBlock;
