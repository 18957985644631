import React, { lazy, Suspense, useContext, useEffect } from "react";
import { Route, Routes } from "react-router";
import { useCookies } from "react-cookie";
import { ServicesContext } from "./context/services.context";
import { fetchServices, Loading, Portal } from "@supermegapay/common";
import NavBar from "./components/NavBar/NavBar";
import HomePage from "./pages/Home/HomePage";
import NotFound from "./pages/NotFound/NotFound";
import Footer from "./components/Footer/Footer";
import CookieConsent from "./components/CookieConsent/CookieConsent";
import "./App.scss";

const TransactionPageLazy = lazy(() =>
  import("./pages/Transaction/TransactionPage")
);
const TransactionResultLazy = lazy(() =>
  import("./pages/TransactionResult/TransactionResult")
);
const ServicesPageLazy = lazy(() =>
  import("./pages/Services/ServicesPage")
);

function App() {
  const [cookies] = useCookies(["cookieConsent"]);
  const { services, setServices } = useContext(ServicesContext);

  useEffect(() => {
    !services && fetchServices(setServices);
  }, [services, setServices]);

  return (
    <div className="App">
      <NavBar />
      <main className="main-content">
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/pay/:id" element={<TransactionPageLazy />} />
            <Route path="/result" element={<TransactionResultLazy />} />
            <Route path="/services/:id" element={<ServicesPageLazy />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </main>
      <Footer />
      {!cookies.cookieConsent && <Portal><CookieConsent /></Portal>}
    </div>
  );
}

export default App;
